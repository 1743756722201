const getAccuracyColor = (accuracy) => {

    if(!accuracy) return 'error';
    if (accuracy <= 100) return 'success';
    if (accuracy < 500) return 'warning';
    return 'error';
  };


const formatSpeed = (speed) => {
    if(!speed) return '🤷';
    const threshold = 1000;
    
    // km/s or m/s
    if (speed >= threshold) {
        // km/s
        return `${Math.round(speed / 1000)} km/s`;

    } else {
        // m/s
        return `${Math.round(speed)} m/s`;
    }
};

const formatDistance = (distance) => {
    const threshold = 1000; // Threshold in meters
  
    if (distance < threshold) {
      // For distances below the threshold, round to the nearest meter
      return `${Math.round(distance)}m`;
    } else {
      // For distances above the threshold, convert to kilometers and round to one decimal point
      const distanceInKm = Math.round(distance / 100) / 10; // Divide by 100 and round to get 1 decimal, then divide by 10 to adjust
      return `~ ${distanceInKm}Km`;
    }
  }

const getAccuracyDescription = (accuracy) => {

    if(!accuracy) 
        return 'Unavailable';

    if (accuracy > 2000) {
      return 'Very Low';
    } else if (accuracy > 1000) {
      return 'Low';
    } else if (accuracy >= 500) {
      return 'Med';
    } else if (accuracy >= 300) {
      return 'Good';
    } else if (accuracy > 50) {
      return 'Very Good';
    } else {
      return 'Excellent';
    }
  };

export { getAccuracyColor, formatDistance, formatSpeed, getAccuracyDescription }
import React, { useState, useEffect } from 'react';
import { Box, Typography, Container, Card, CardContent, LinearProgress, Grid, Button, Snackbar, Alert, AppBar, Toolbar } from '@mui/material';
import MapIcon from '@mui/icons-material/Map';
import { getDeviceName } from './helpers/DeviceName.js';
import { getBrowserName } from './helpers/BrowserName.js';
import { getConnectionType } from './helpers/ConnectionType.js';
import { getIPAddress } from './helpers/IPAddress.js';
import { openInGoogleMaps } from './helpers/openInGoogleMaps.js';
import { fetchAddress } from './helpers/fetchAddress.js';
import { getAccuracyColor, formatDistance, formatSpeed, getAccuracyDescription } from './helpers/formatters.js'
import { useGeolocation } from './hooks/useGeolocation';

const App = () => {

  const [browserName, setBrowserName] = useState('');
  const [deviceType, setDeviceType] = useState('');
  const [connectionType, setConnectionType] = useState('');
  const [IPAddress, setIPAddress] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { position, address } = useGeolocation(fetchAddress);

  const handleOpenSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };  

  const checkGeolocationEnabled = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        reject(new Error("Geolocation is not supported by your browser."));
        return;
      }

      navigator.geolocation.getCurrentPosition(
        () => resolve(true),
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            resolve(false);
          } else {
            reject(new Error("Error checking geolocation status."));
          }
        }
      );
    });
  };

  useEffect(() => {

    setBrowserName(getBrowserName());
    setDeviceType(getDeviceName());
    setConnectionType(getConnectionType());
    getIPAddress().then(ip => setIPAddress(ip));

    //  Detect if Geolocation API is enabled or not
    checkGeolocationEnabled()
    .then((isEnabled) => {
      if (isEnabled) {
        handleOpenSnackbar("Geolocation API accessed successfully", "success");
      } else {
        handleOpenSnackbar("Geolocation is disabled.", "error");
      }
    })
    .catch((error) => {
      handleOpenSnackbar(error.message, "error");
    });      


  }, []);

  return (

    <>
      <AppBar position="static">
        <Toolbar sx={{ bgcolor: 'grey.900' }}>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Geolocation Info
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm">

      <Box sx={{ padding: 2, marginTop: 2 }}>
        <Card variant="outlined" sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }} >Location Information</Typography>
                <Typography>Latitude: {position.latitude}</Typography>
                <Typography>Longitude: {position.longitude}</Typography>
                <Typography>Altitude: {formatDistance(position.altitude) || '🤷'}</Typography>
                <Typography>Speed: {formatSpeed(position.speed)}</Typography>
                <Typography>Heading: {position.heading ? position.heading + '°' : '🤷'}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{  marginTop: 2 }}>
        <LinearProgress variant="determinate" value={100 - Math.min(position.accuracy, 100)} color={getAccuracyColor(position.accuracy)} />
          <CardContent>
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }} >Accuracy</Typography>
            <Typography>Level: {getAccuracyDescription(position.accuracy)}</Typography>
            <Typography>Radius: {formatDistance(position.accuracy)}</Typography>
          </CardContent>
        </Card>

        <Card variant="outlined" sx={{  marginTop: 2 }}>
          <CardContent>
          <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }} >Location on Map</Typography>
            <Typography>{address || 'Unknown'}</Typography><br/>
            <Button
              variant="contained"
              color="primary"
              startIcon={<MapIcon />}
              onClick={() => openInGoogleMaps(position.latitude, position.longitude)}
              disabled={!position.latitude || !position.longitude}
          >
            Open in Google Maps
          </Button>
        </CardContent>
        </Card>

        <Card variant="outlined" sx={{  marginTop: 2 }}>
          <CardContent>
            <Typography sx={{ fontWeight: 'bold', marginBottom: 1 }} >Device Info</Typography>
            <Typography>{deviceType}</Typography>
            <Typography>{browserName}</Typography>
            <Typography>{connectionType} connection type</Typography>
            <Typography>IP Address: {IPAddress}</Typography>
            
          </CardContent>
        </Card>    

          <Card variant="outlined" sx={{  marginTop: 2 }}>
          <CardContent>
            <Typography sx={{ fontWeight: 'ligh', fontSize: '10px' }} >Version 1.01</Typography>
          </CardContent>
        </Card>    

      </Box>

      <Snackbar 
        open={openSnackbar} 
        autoHideDuration={5000} 
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      </Container>      
    </>

  );
};

export default App;
const fetchAddress = async (latitude, longitude) => {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
      const data = await response.json();
      return data.address ? `${data.address.road}, ${data.address.city}, ${data.address.country}` : 'Address not found';
    } catch(e) {
      return 'No response from address provider 😣';
    }
  };

export { fetchAddress } 
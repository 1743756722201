const getDeviceName = () => {
    const ua = navigator.userAgent;
  
    // Basic mappings for demonstration purposes
    const osRegexMappings = [
      { regex: /Windows NT 10.0;?/, name: 'Windows 10' },
      { regex: /Windows NT 6.3;?/, name: 'Windows 8.1' },
      { regex: /Macintosh;.*Mac OS X (\d+)_\d+/, name: 'macOS' },
      { regex: /iPhone;.*OS (\d+)_\d+/, name: 'iOS' },
      { regex: /iPad;.*OS (\d+)_\d+/, name: 'iPadOS' },
      { regex: /Android (\d+\.\d+)/, name: 'Android' },
    ];
  
    let osName = "Unknown OS";
    osRegexMappings.forEach(os => {
      if (os.regex.test(ua)) {
        const match = ua.match(os.regex);
        if (match && match[1]) {
          // Append version number for macOS and iOS/iPadOS
          if (os.name === 'macOS' || os.name === 'iOS' || os.name === 'iPadOS') {
            osName = `${os.name} ${match[1].replace('_', '.')}`;
          } else if (os.name === 'Android') {
            osName = `${os.name} ${match[1]}`;
          } else {
            osName = os.name;
          }
        } else {
          osName = os.name;
        }
      }
    });
  
    // Fallback to generic device type if specific model or OS is not detected
    let deviceType = "Unknown Device";
    if (/Mobile|Android|iPhone|iPad|iPod/i.test(ua)) {
      deviceType = "Mobile Device";
    } else if (/Windows|Macintosh|Mac OS|Linux/i.test(ua)) {
      deviceType = "Desktop Device";
    }
  
    return `${deviceType} - ${osName}`;
  };

  export { getDeviceName };
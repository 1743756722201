import { useState, useEffect } from 'react';

const useGeolocation = (fetchAddress) => {
  const [position, setPosition] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    speed: null,
    heading: null,
    accuracy: null,
  });
  const [address, setAddress] = useState('');
  const [lastCoords, setLastCoords] = useState({ latitude: null, longitude: null });

  useEffect(() => {
    const significantChange = (lat1, lon1, lat2, lon2) => {
      return Math.abs(lat1 - lat2) > 0.001 || Math.abs(lon1 - lon2) > 0.001;
    };

    if (navigator.geolocation) {
      navigator.geolocation.watchPosition(async (pos) => {
        const { latitude, longitude, altitude, speed, heading, accuracy } = pos.coords;
        setPosition({ latitude, longitude, altitude, speed, heading, accuracy });

        if (!lastCoords.latitude || !lastCoords.longitude || significantChange(latitude, longitude, lastCoords.latitude, lastCoords.longitude)) {
          const fetchedAddress = await fetchAddress(latitude, longitude);
          setAddress(fetchedAddress);
          setLastCoords({ latitude, longitude });
        }
      }, (err) => console.log(err), {
        enableHighAccuracy: true,
        maximumAge: 0,
        timeout: 5000,
      });
    }
  }, [lastCoords, fetchAddress]);

  return { position, address };
};

export { useGeolocation };